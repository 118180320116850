<template>
  <div>
    <p class="p-6 text-center text-grey">
      Kindly reach out to support at
      <a
        href="mailto:support@zilla.africa"
        target="_blank"
        class="text-brandPurple"
        >support@zilla.africa</a
      >
      to unblock this card
    </p>
    <!-- <div v-if="tab === 'initiate'" class="mb-4">
      <p class="text-lg text-dark">Unfreeze card</p>
      <p class="mt-4 text-sm text-grey">
        Activate your card to perform online transactions.
      </p>
      <div class="spam-notice my-8 rounded-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#0EA2F5"
          />
        </svg>
        <p class="text-sm text-dark text-left">
          Verification will be done with OTP
        </p>
      </div>
      <Button width="w-full" text="Unfreeze my card" @click="tab = 'otp'" />
    </div>
    <div v-else class="mb-4">
      <p class="text-lg text-dark">Enter OTP</p>
      <p class="mt-4 text-sm text-grey">
        We sent a secret code to your email - {{ userBio.email }}
      </p> -->

    <!-- OTP section -->
    <!-- <OtpInput
        class="mt-8"
        @on-change="handleOnChange"
        @on-complete="handleOnChange"
      />
      <Spinner v-if="verifyLoading" />
      <div class="mt-4 flex" v-else>
        <p
          class="underline text-sm text-brandPurple cursor-pointer"
          v-if="showResendButton"
          @click="resendOtp"
        >
          Resend OTP
        </p>
        <div class="ml-auto" v-else>
          <vue-countdown
            :time="60 * 1000"
            v-slot="{ minutes, seconds }"
            @end="showResendButton = true"
          >
            <span class="text-sm text-grey">{{ minutes }}:{{ seconds }}</span>
          </vue-countdown>
        </div>
      </div> -->
    <!-- OTP section -->

    <!-- <div class="spam-notice my-8 rounded-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#0EA2F5"
          />
        </svg>
        <p class="text-sm text-dark text-left">
          If you don’t see our email, please check your spam folder
        </p>
      </div>
      <Button width="w-full" text="Verify Email" @click="showSuccess" />
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { OtpInput } from "@/UI/Input";
import { Button } from "@/UI/Button";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  components: {
    Button,
    OtpInput,
    Spinner: () => import("@/UI/Spinner"),
    "vue-countdown": VueCountdown,
  },

  data() {
    return {
      tab: "initiate",
      otp: "",
      verifyLoading: false,
      showResendButton: false,
    };
  },
  computed: {
    ...mapState({
      userBio: (state) => state?.auth?.user,
    }),
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    handleOnChange(value) {
      this.otp = value;
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
    resendOtp() {
      this.verifyLoading = true;
      this.sendBvnOtp(this.bvn)
        .then(() => {
          this.verifyLoading = false;
          this.showToast({
            display: true,
            icon: "success",
            description: "A new otp has been sent to your email",
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.verifyLoading = false;
        });
    },
    showSuccess() {
      this.showAlert({
        display: true,
        type: "green-success",
        title: "Card unfrozen successfully",
        description: `Your card is now active and you can perform transactions.`,
        buttonText: "Okay",
      });
    },
  },
};
</script>

<style>
</style>