var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "p-6 text-center text-grey" }, [
        _vm._v(" Kindly reach out to support at "),
        _c(
          "a",
          {
            staticClass: "text-brandPurple",
            attrs: { href: "mailto:support@zilla.africa", target: "_blank" },
          },
          [_vm._v("support@zilla.africa")]
        ),
        _vm._v(" to unblock this card "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }